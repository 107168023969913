import ContactSection from "../../components/contact-section/ContactSection";
import HomeHeader from "../../components/home-header/home-header";
import NosotrosSection from "../../components/nosotros/nosotrosSection";
import ServicesSection from "../../components/services-section/ServicesSection";
import './home.css'

const Homepage = () => {

    return (
        <>
        <header>
            <HomeHeader/>
        </header>
        <main>
            <ServicesSection/>
            <NosotrosSection/>
            <ContactSection/>
        </main>
        


            
        </>
    );
}

export default Homepage;