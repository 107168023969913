import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import NavBar from "../components/navbar/navbar";
import Homepage from "../pages/home/home";
import Footer from "../components/footer/footer";

const Routing = () => {
    return (
        <>
            <Router>
                <div className="App">
                    <NavBar/>
                    <Routes>
                        <Route path={"/"} element={<Homepage/>} />
                    </Routes>
                    <Footer/>
                </div>
            </Router>
        </>
    );
}

export default Routing;