import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import './ContactSection.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function ContactSection() {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [fieldErrors, setFieldErrors] = useState({});

  const handleSubmit = (event) => {
    event.preventDefault();

    const messageHtml = event.target.elements.message_html.value;
    const fromName = event.target.elements.from_name.value;
    const fromEmail = event.target.elements.from_email.value;

    if (!messageHtml || !fromName || !fromEmail) {
      toast.error('Por favor, completa todos los campos.');
      
      const errors = {};
      if (!messageHtml) {
        errors.message_html = true;
      }
      if (!fromName) {
        errors.from_name = true;
      }
      if (!fromEmail) {
        errors.from_email = true;
      }

      setFieldErrors(errors);
      
      return;
    }

    const btn = document.getElementById('button-contacto');
    btn.value = 'Enviando...';
    setIsSubmitting(true);

    const serviceID = 'service_a4ilpf3';
    const templateID = 'template_dww4wrb';
    const userID = 'JUzrbz6zgl0XzCKxS';

    emailjs
      .sendForm(serviceID, templateID, event.target, userID)
      .then(() => {
        btn.value = 'Enviar Mensaje';
        toast.success('¡Mensaje enviado!');
        setIsSubmitting(false);
        setFieldErrors({});
      })
      .catch((err) => {
        btn.value = 'Enviar Mensaje';
        setIsSubmitting(false);
        toast.error('Error al enviar el mensaje');
      });
  };

  return (
    <>
      <form id="contacto" onSubmit={handleSubmit}>
      <h1>¡Contactanos!</h1>
        <div className={`field ${fieldErrors.from_name ? 'field-error' : ''}`}>
          <p htmlFor="from_name">Nombre y Apellido</p>
          <input type="text" name="from_name" id="from_name" placeholder="Ingrese su Nombre y Apellido"/>
        </div>
        <div className={`field ${fieldErrors.from_email ? 'field-error' : ''}`}>
          <p htmlFor="from_email">Email</p>
          <input type="text" name="from_email" id="from_email" placeholder="Ingrese su Email"/>
        </div>
        <div className="field">
          <p htmlFor="phone">Telefono</p>
          <input type="text" name="phone" id="phone" placeholder="Ingrese su Telefono"/>
        </div>
        <div className={`field ${fieldErrors.message_html ? 'field-error' : ''}`}>
          <p htmlFor="message_html">Consulta</p>
          <textarea type="text" name="message_html" id="message_html" placeholder="Ingrese su consulta" />
        </div>
        <div className="btn-container">
          <button type="submit" className="btn-contacto" id="button-contacto" disabled={isSubmitting} style={{ backgroundColor: isSubmitting ? '#e09a30' : '#da8f1f' }}>
            {isSubmitting ? 'Enviando...' : 'Enviar mensaje'}
          </button>
        </div>
      </form>

      <ToastContainer />
    </>
  );
}

export default ContactSection;