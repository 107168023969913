import './home-header.css'
import AcUnitIcon from '@mui/icons-material/AcUnit';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const HomeHeader = () => {


    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        arrows: false
      };

    return (
        <>
            <div className='header-position' id='inicio'>
                <Slider {...settings}>
                    <div className='carousel-header'>
                        <img src={process.env.PUBLIC_URL + "/img/mantenimiento-rl.png"} className='header-bg' alt='mantenimiento-rl'/>
                        <div className='overlay'>
                        <div className='text-header'>
                            <h1>Mantenimiento de Chillers<AcUnitIcon  style={{ color: '#ff8c00', fontSize: '50px' }}/></h1>
                            <a className="square-button" href="#nosotros">Ver más</a>
                        </div>
                        </div> 
                    </div>
                    <div className='carousel-header'>
                        <img src={process.env.PUBLIC_URL + "/img/header-rl.png"} className='header-bg' alt='reparacion-rl'/>
                        <div className='overlay'>
                        <div className='text-header'>
                            <h1>Reparacion de Chillers<i className="fas fa-wrench" style={{ color: '#ff8c00', fontSize: '50px' }}></i>  </h1>
                            <a className="square-button" href="#nosotros">Ver más</a>
                        </div>
                        </div> 
                    </div>
                    <div className='carousel-header'>
                        <img src={process.env.PUBLIC_URL + "/img/limpieza-rl.png"} className='header-bg' alt='limpieza-rl'/>
                        <div className='overlay'>
                        <div className='text-header'>
                            <h1>Limpieza Química<i className="fas fa-flask" style={{ color: '#ff8c00', fontSize: '50px' }}></i>  </h1>
                            <a className="square-button" href="#nosotros">Ver más</a>
                        </div>
                        </div> 
                    </div>
                    <div className='carousel-header'>
                        <img src={process.env.PUBLIC_URL + "/img/mecanica-rl.png"} className='header-bg' alt='mecanica-rl'/>
                        <div className='overlay'>
                        <div className='text-header'>
                            <h1>Limpieza Mecánica<i className="fas fa-cogs" style={{ color: '#ff8c00', fontSize: '50px' }}></i> </h1>
                            <a className="square-button" href="#nosotros">Ver más</a>
                        </div>
                        </div> 
                    </div>
                </Slider>
            </div>
        </>
    );
}
export default HomeHeader;