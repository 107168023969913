import './App.css';
import Routing from './routing/routing';

function App() {
  return (
    <Routing/>
  );
}

export default App;
