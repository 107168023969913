import './navbar.css'
import { useState, useEffect } from "react";

const NavBar = () => {
    const [scrolled, setScrolled] = useState(false);

    useEffect(() => {
        function handleScroll() {
          if (window.scrollY > 0) {
            setScrolled(true);
          } else {
            setScrolled(false);
          }
        }
        window.addEventListener("scroll", handleScroll);
        return () => {
          window.removeEventListener("scroll", handleScroll);
        };
      }, []);
    return (
        <>
        <nav className={`navbar ${scrolled ? "navbar-scrolled" : ""}`}>
          <div className='navbar-content'>
            <div className="logo">
                <img src={process.env.PUBLIC_URL + "/img/logo-rl.png"} alt="RL Soluciones"/>
                <span><span className='resaltado-azul'>R</span><span className='resaltado-y'> & </span><span className='resaltado-naranja'>L</span> Servicios</span>
            </div>
            <ul className="links">
                <li><a href="#inicio"> Inicio</a></li>
                <li><a href="#nosotros">Sobre Nosotros</a></li>
                <li><a href="#contacto">Contacto</a></li>
            </ul>
          </div>
        </nav>
        </>
    );
}

export default NavBar