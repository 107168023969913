import React from "react";
import './footer.css'

const Footer = () => {
    return (
    <>
    <footer className="section-footer">
        <div className="footer-top">
            <div className="footer-logo">
                <img src={process.env.PUBLIC_URL + "/img/logo-rl.png"} alt="Logo-empresa"/>
                <span><span className='resaltado-azul'>R</span><span className='resaltado-y'> & </span><span className='resaltado-naranja'>L</span> Servicios</span>
            </div>
            <ul className="footer-links">
                <li><a href="#inicio">La Empresa</a></li>
                <li><span className="separator">-</span></li>
                <li><a href="#nosotros">Servicios</a></li>
                <li><span className="separator">-</span></li>
                <li><a href="#contacto">Contacto</a></li>
            </ul>
        </div>
        <div className="footer-line"></div>
        <div className="footer-bottom">
            <div className="footer-legal">
                <span>R&L Servicios© 2023 Todos los derechos reservados</span>
                <span> -</span>
                <a> Tel: +541127202334</a>
                <span> -</span>
                <a> Terminos y condiciones</a>
                <span> -</span>
                <a> Privacidad </a>
            </div>
        </div>
    </footer>
    </>

    );
  };
  
  export default Footer;