import "./nosotros.css"

const NosotrosSection = () => {

    return (
        <div className="centrado-blanco">
            <span className="texto-centrado">
                <h1>R&L Servicios expertos en Chillers</h1>
                <h2>Somos una empresa dedicada al mantenimiento y reparación de equipos chillers en toda Argentina y países limítrofes. </h2>
                <h2>Nuestro personal altamente calificado y capacitado está comprometido en ayudar y acompañar a nuestros clientes para mantener el correcto funcionamiento de sus instalaciones, ya sea para confort o producción.
                Con una experiencia de 18 años en el mercado, nos avalamos especialmente en el mantenimiento de equipos de la marca YORK. La satisfacción de nuestros clientes nos ha convertido en una empresa recomendada en el sector.
                Además de nuestros servicios de mantenimiento y reparación, también ofrecemos servicios de limpieza química y mecánica de condensadores, asegurando el óptimo rendimiento de los equipos y prolongando su vida útil.</h2>
                <h2>¡Confía en R&L Servicios para tus necesidades de mantenimiento y reparación de equipos chillers! Estamos comprometidos en brindar un servicio de calidad y confiable a nuestros clientes en Argentina y países limítrofes.</h2>
            </span>
        </div>

    );
}

export default NosotrosSection;