import React from "react";
import './ServicesSection.css'
import AcUnitIcon from '@mui/icons-material/AcUnit';

function ServicesSection() {
    return (
      <section id="service-section">
        <div className="row services-container" id="nosotros">
        <h2>Servicios</h2>
          <div className="col-md-3 col-sm-6">
            <div className="service">
              <img src={process.env.PUBLIC_URL + "/img/mantenimiento-rl.png"} className='service-image' alt='mantenimiento-rl'/>
              <h3>Mantenimiento <AcUnitIcon  style={{ color: 'e09a30', fontSize: '20px' }}/></h3>
              <p className="service-description">Ofrecemos servicios de mantenimiento preventivo y correctivo para mantener tus Chillers en óptimas condiciones. </p>
            </div>
          </div>
          <div className="col-md-3 col-sm-6">
            <div className="service">
              <img src={process.env.PUBLIC_URL + "/img/header-rl.png"} className='service-image' alt='reparacion-rl'/>
              <h3>Reparacion <i className="fas fa-wrench" style={{ color: 'e09a30', fontSize: '20px' }}></i></h3>
              <p className="service-description">Contamos con un equipo de técnicos especializados para resolver cualquier problema que puedan presentar tus Chillers. </p>
            </div>
          </div>
          <div className="col-md-3 col-sm-6">
            <div className="service">
              <img src={process.env.PUBLIC_URL + "/img/limpieza-rl.png"} className='service-image' alt='limpieza-rl'/>
              <h3>Limpieza quimica <i className="fas fa-flask" style={{ color: 'e09a30', fontSize: '20px' }}></i></h3>
              <p className="service-description">Realizamos limpieza química para eliminar la suciedad acumulada en tus Chillers y mejorar su rendimiento.</p>
            </div>
          </div>
          <div className="col-md-3 col-sm-6">
            <div className="service">
              <img src={process.env.PUBLIC_URL + "/img/mecanica-rl.png"} className='service-image' alt='mecanica-rl'/>
              <h3>Limpieza mecanica <i className="fas fa-cogs" style={{ color: 'e09a30', fontSize: '20px' }}></i></h3>
              <p className="service-description">Realizamos limpieza mecánica para eliminar la suciedad y obstrucciones en tus Chillers y asegurar su correcto funcionamiento.  </p>
            </div>
          </div>

        </div>
      </section>
    );
  }
  
  export default ServicesSection;
